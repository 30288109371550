<template>
  <div class="subapp">
    <div class="sub-banner bg" />
    <div class="wrapper">
      <div class="bread">
        <div class="icon"><i class="el-icon-caret-right" /></div>
        <div class="text">您的位置：首页  <i>></i>  <span>APP介绍及下载</span></div>
      </div>
      <div class="sub-menu">
        <a href="/baseHome/Platform" title="网络货运平台">网络货运平台</a>
        <a href="/baseHome/AppDownLoad" title="APP介绍及下载" class="on">APP介绍及下载</a>
      </div>
    </div>
    <!-- scales -->
    <div class="wrapper">
      <div class="sub-title">APP介绍</div>
      <div class="appdown">
        <div class="titless">企业端APP</div>
        <div class="cont">
          <span>●</span>货源、运力实时匹配，用户在线发布运输需求、及时处理订单。<br>
          <span>●</span>电子合同、电子签章、电子签名、安全快捷、省去中间沟通成本。<br>
          <span>●</span>业务、合同、财务高效关联，运营更清晰。<br>
          <span>●</span>丰富的App功能方便客户随时随地跟踪货物轨迹，有效提升客户服务体验。
        </div>
        <div class="img rightToLeft"><img src="../../assets/images/app1.png"></div>
      </div>

      <div class="appdown" style="margin-left:590px;">
        <div class="titless">司机端APP</div>
        <div class="cont">
          <span>●</span>支持在线接单，待发货订单、自提货订单等信息及时掌握。<br>
          <span>●</span>在线回单拍照，实时电子签收，全程可视化。<br>
          <span>●</span>异常在线实时上报，快速高效协同处理。<br>
          <span>●</span>对接三方电子支付平台，实现实时支付或收款。
        </div>
        <div class="img1 leftToRight"><img src="../../assets/images/app2.png"></div>
      </div>
      <div class="appdown">
        <div class="titless">收单员APP</div>
        <div class="cont">
          <span>●</span>专门给企业财务人员开发的APP。<br>
          <span>●</span>业务、合同、财务高效关联，运营更清晰。<br>
          <span>●</span>丰富的App功能方便客户随时随地跟踪货物轨迹，有效提升客户服务体验。
        </div>
        <div class="img rightToLeft"><img src="../../assets/images/app3.png"></div>
      </div>
    </div>
    <div class="appxz">
      <div class="wrapper">
        <div class="appxz1">
          <h3>山水物联APP下载</h3>
          <img src="../../assets/images/appmm.jpg"><br>
          Android下载
        </div>
      </div>
    </div>

  </div>

</template>

<script>
// import '../../assets/js/scrollSlide'
export default {
  name: '',
  components: {
  },
  data() {
    return {

    }
  },

  mounted() {
    console.log($('#app'))
  },
  methods: {

  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/sub.scss";
.subapp {
  .bg{background:url(./../../assets/images/cpbg.jpg) }
  .circles{position: relative; margin-top: 20px;
    .circles1{position: absolute; top: 50%; left: 50%; margin-top: -163px; margin-left: -163px;animation:circleRoate 40s infinite linear;}
    .circles2{position: absolute; top: 50%; left: 50%; margin-top: -225px; margin-left: -225px;animation:circleRoate1 40s infinite linear;}
  }
  .appdown{position: relative; padding: 55px 0 0 30px; min-height: 450px;margin-bottom: 70px;
    .titless{ line-height: 39px; border-left: #2679e0 solid 9px; padding-left: 19px; font-size: 28px; color: #222;}
    .cont{ line-height: 52px; font-size: 16px; color: #222; padding:28px 0 0 27px;
      span{ color: #0062db; font-weight: bold; font-size: 20px; padding-right: 10px;}
    }
    .img{position: absolute; top: 0; right:0;}
    .img1{position: absolute; top: 0; left:-560px;}
  }
  .appxz{ height: 559px; background:url(./../../assets/images/appbg.jpg);
    .appxz1{ width: 430px; text-align: center; color: #fff; font-size: 18px; padding-top: 132px;
      img{ margin:45px 0 23px;}
      h3{ font-size: 40px; font-weight: bold; letter-spacing: 6px;}
    }
  }

}

</style>
